import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { getImgixUrl } from '../../../../helpers';
import { SiteBuilderModule } from '../sharedComponents';
import {
  CARD,
  IMAGE,
  IMAGERIGHT,
  LARGE_IMAGE,
  LARGE_VIDEO,
  LIGHT,
  STANDARD,
  VIDEO,
  XL_VIDEO,
  FOREGROUND_50_50,
  BACKGROUND_50_50,
} from './constants';
import {
  ImageDisplayContent,
  ImageRightDisplayContent,
  LargeImageDisplayContent,
  LargeVideoDisplayContent,
  LightDisplayContent,
  StandardDisplayContent,
  VideoDisplayContent,
  CardDisplayContent,
  ForegroundFiftyFiftyDisplayContent,
  BackgroundFiftyFiftyDisplayContent,
} from './displayStyles';
import { ModuleWrapper, OverlayWrapper } from './styles';

const moduleWrapperHeight = {
  XL_VIDEO: {
    minHeight: '480px',
    tablet: {
      minHeight: '812px',
    },
  },
};

const HeroModule = (props) => {
  const {
    section: {
      shown,
      colorType = '',
      displayType = '',
      textAlignment = 'CENTRE',
      cardAlignment = 'CENTRE',
      opacityType,
      background: { path: backgroundImage } = {},
    } = {},
    pageConfig: { tenantId },
  } = props;

  const themeConfig = useContext(ThemeContext);
  const displayStyle = displayType === XL_VIDEO ? LARGE_VIDEO : displayType;

  if (!shown) {
    return null;
  }

  const { heroModule } = themeConfig.siteBuilderModules;
  const vStyles = heroModule.visualStyles[displayStyle][colorType];
  const dStyles = heroModule.displayStyles[displayStyle];

  const backgroundPath = getImgixUrl(tenantId, backgroundImage);

  let alignStyle = textAlignment;

  let displayContent;
  switch (displayType) {
    case IMAGE: {
      displayContent = <ImageDisplayContent section={props.section} />;
      break;
    }
    case VIDEO: {
      displayContent = <VideoDisplayContent section={props.section} />;
      break;
    }
    case LARGE_IMAGE: {
      displayContent = <LargeImageDisplayContent section={props.section} />;
      break;
    }
    case LARGE_VIDEO:
    case XL_VIDEO: {
      displayContent = <LargeVideoDisplayContent section={props.section} />;
      break;
    }
    case LIGHT: {
      displayContent = <LightDisplayContent section={props.section} />;
      break;
    }
    case STANDARD: {
      displayContent = <StandardDisplayContent section={props.section} />;
      break;
    }
    case IMAGERIGHT: {
      displayContent = (
        <ImageRightDisplayContent section={props.section} tenantId={tenantId} />
      );
      break;
    }
    case CARD: {
      alignStyle = cardAlignment;
      displayContent = (
        <CardDisplayContent
          section={props.section}
          backgroundPath={backgroundPath}
        />
      );
      break;
    }
    case FOREGROUND_50_50:
      return (
        <SiteBuilderModule
          section={props.section}
          moduleTheme={{ dStyles, vStyles }}
        >
          <ForegroundFiftyFiftyDisplayContent section={props.section} />
        </SiteBuilderModule>
      );
    case BACKGROUND_50_50:
      return (
        <SiteBuilderModule
          section={props.section}
          moduleTheme={{ dStyles, vStyles }}
        >
          <BackgroundFiftyFiftyDisplayContent section={props.section} />
        </SiteBuilderModule>
      );
    default: {
      displayContent = null;
      break;
    }
  }

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles, alignStyle, opacityType }}
    >
      <ModuleWrapper
        data-background-image={backgroundPath}
        className="lazy"
        data-testid="hero-module-wrapper"
        minHeight={moduleWrapperHeight[displayType]}
      >
        <OverlayWrapper />
        {displayContent}
      </ModuleWrapper>
    </SiteBuilderModule>
  );
};

HeroModule.propTypes = {
  section: PropTypes.shape({
    shown: PropTypes.bool,
    displayType: PropTypes.oneOf([
      'VIDEO',
      'IMAGE',
      'IMAGERIGHT',
      'LARGE_IMAGE',
      'LARGE_VIDEO',
      'XL_VIDEO',
      'STANDARD',
      'LIGHT',
      'CARD',
      'BACKGROUND_50_50',
      'FOREGROUND_50_50',
    ]),
    colorType: PropTypes.string.isRequired,
    opacityType: PropTypes.oneOf(['FIFTYPERCENT', 'HUNDREDPERCENT']),
    backgroundImageAnchor: PropTypes.oneOf(['LEFT', 'RIGHT', 'CENTRE', 'NONE']),
    cardAlignment: PropTypes.oneOf(['LEFT', 'RIGHT', 'CENTRE']),
    textAlignment: PropTypes.oneOf(['LEFT', 'RIGHT', 'CENTRE']),
    background: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
  pageConfig: PropTypes.shape({
    tenantId: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  pageConfig: state.pageConfig,
});

export default connect(mapStateToProps)(HeroModule);
